import { useEffect, useState } from "react";
import axios from "axios";
import Chart1 from "./Components/Chart1";
import Chart2 from "./Components/Chart2";
import  './datepicker.scss'
import 'antd/dist/antd.css';
import "./App.css";
import { Card, Col, Row } from "antd";
import darkTheme from '@ant-design/dark-theme';
import SharedTable from "./Components/SharedTable";

function App() {
  const [data, setData] = useState([]);
  const getData = (from, to) => {
    axios
      .get("https://grijanje.simundza.com/api/getData", { params: { from, to } })
      .then((resp) => {
        setData(resp.data);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="wrapper">
      <div class="content-area">
            <Row gutter={16} class="row mt-4">
            <img src="/images/logo.png" style={{maxWidth:'400px',margin:'30px auto'}}/>
            
              <Col xs={24}>
                <div class="box mt-4">
                    <Chart1 data={data} getData={getData} />
                </div>
                <div class="box" style={{paddingTop:0}}>
                    <Chart2 data={data}/>
                </div>

                <div class="box mt-4">
                    <SharedTable data={data} />
                </div>
              </Col>
            </Row>
      </div>
    </div>
  );
}

export default App;
