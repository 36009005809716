import React, { useEffect, useState } from "react";
import {
  apexLineChartDefaultOption,
  COLOR_3,
  COLOR_4,
  COLOR_5,
} from "../../chartConstants";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import dayjs from "dayjs";
import "dayjs/locale/hr";
import { DatePicker, RangeDatePicker } from "@y0c/react-datepicker";
import axios from "axios";
export default function Chart1({ data, getData }) {
  const [series, setSeries] = useState([]);
  const [stats, setStats] = useState([]);

  const handleDatesChange = (from, to) => {
    if (from && to) {
      getData(from, to);
    }
  };
  useEffect(() => {
    if (data) {
      setStats(data);
    }
  }, [data]);

  useEffect(() => {
    let processed = {
      fan: [],
      t_voda: [],
      t_zrak: [],
    };
    stats.map((item) => {
      processed["fan"].push({
        x: dayjs(item.created_at).valueOf(),
        y: item.fan * 10,
      });
      processed["t_voda"].push({
        x: dayjs(item.created_at).valueOf(),
        y: item.t_voda,
      });
      processed["t_zrak"].push({
        x: dayjs(item.created_at).valueOf(),
        y: item.t_zrak,
      });
    });

    setSeries([
      { name: "Fan", data: processed["fan"] },
      { name: "Temperatura vode", data: processed["t_voda"] },
      {
        name: "Temperatura zraka",
        data: processed["t_zrak"],
      },
    ]);
  }, [stats]);

  const zoomOptions = [
    { value: 6, unit: "hours", text: "6 Sati" },
    { value: 12, unit: "hours", text: "12 Sati" },
    { value: 24, unit: "hours", text: "24 Sata" },
    { value: 48, unit: "hours", text: "48 Sati" },
    { value: 72, unit: "hours", text: "72 Sata" },
  ];

  const zoomDateRange = (zoomOption) => {
    if (zoomOption) {
      ApexCharts.exec(
        "chart1",
        "zoomX",
        dayjs().subtract(zoomOption.value, zoomOption.unit).valueOf(),
        dayjs().valueOf()
      );
      ApexCharts.exec(
        "chart2",
        "zoomX",
        dayjs().subtract(zoomOption.value, zoomOption.unit).valueOf(),
        dayjs().valueOf()
      );
    }
  };
  return (
    <div>
      {zoomOptions && (
        <>
          <h3>Grafikon 1</h3>

          <div className="datepickerWrapper">
          <div style={{ display: "flex", flexDirection: "column" }}>
              <RangeDatePicker
                onChange={handleDatesChange}
                initialStartDate={dayjs().subtract(7, "day")}
                initialEndDate={dayjs()}
                locale="hr"
              />
            </div>
            <div
              className="select"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <select
                onChange={(key) => zoomDateRange(zoomOptions[key.target.value])}
                style={{ width: 120 }}
              >
                <option value={9999}>Period prikaza</option>
                {zoomOptions.map((zoomOption, key) => (
                  <option key={key} value={key}>
                    {zoomOption.text}
                  </option>
                ))}
              </select>
            </div>
           
          </div>
        </>
      )}
      <Chart
        options={{
          ...apexLineChartDefaultOption,
          colors: [COLOR_3, COLOR_4, COLOR_5],
          stroke: {
            curve: ["stepline", "smooth", "smooth"],
            width:2
          },

          tooltip: {
            theme: "dark",
            y: {
              formatter: function (value, { series, seriesIndex }) {
                if (seriesIndex === 0) {
                  if (value > 0) {
                    return "ON";
                  } else {
                    return "OFF";
                  }
                }
                return value + "°C";
              },
            },
            x: {
              show: true,
              format: "dd MMM y HH:mm",
              formatter: undefined,
            },
          },
          chart: {
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
            },
            id: "chart1",
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
            },
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        }}
        series={series}
        type="line"
        width="100%"
        height="450px"
      />
    </div>
  );
}
