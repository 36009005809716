export const COLOR_1 = "#9BBB59";
export const COLOR_2 = "#02B050";
export const COLOR_3 = "#92D050";
export const COLOR_4 = "#0270C0";
export const COLOR_5 = "#00B0EF";
export const COLOR_6 = "#901616";
export const COLOR_7 = "#c70a0a";
export const COLOR_8 = "#C0504D";


export const COLORS = [
  COLOR_1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
  COLOR_5,
  COLOR_6,
  COLOR_7,
];

export const apexLineChartDefaultOption = {
  chart: {
    zoom: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },  
  },
  colors: [...COLORS],
  tooltip: {
    theme: "dark",

  },
  grid: {
    borderColor: "#40475D",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 3,
    curve: "smooth",
    lineCap: "round",
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
    offsetY: 15,
    itemMargin: {
      vertical: 20,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    
  },
  xaxis: {
    categories: [],
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
};
