import React, { useEffect, useState } from "react";
import { Table } from "antd";
import dayjs from "dayjs";
import { RangeDatePicker } from "@y0c/react-datepicker";
import axios from "axios";

export default function SharedTable() {
  const columns = [
    {
      title: "Datum",
      key: "created_at",
      width:170,
      render: (record) =>
        dayjs(record.created_at).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Fan",
      key: "fan",
      width:50,
      render: (record) =>
        record.fan ? <img src="/images/zelena_ledica.png" /> : "-",
    },
    {
      title: "T. Vode",
      width:50,
      key: "t_voda",
      render: (record) => `${record.t_voda}°C`,
    },
    {
      title: "T. Zraka",
      width:50,
      key: "t_zrak",
      render: (record) => `${record.t_zrak}°C`,
    },
    {
      title: "Silos 1",
      key: "silos1",
      width:90,
      render: (record) =>
        record.silos1 ? <img src="/images/Silos1.png" /> : "-",
    },
    {
      title: "Silos 2",
      key: "silos2",
      width:90,
      render: (record) =>
        record.silos2 ? <img src="/images/Silos2.png" /> : "-",
    },
    {
      title: "L. Sw. 1",
      key: "LimitSw1",
      width:90,
      render: (record) =>
        record.LimitSw1 ? <img src="/images/limit_switch_easy.jpeg" /> : "-",
    },
    {
      title: "L. Sw. 2",
      key: "LimitSw2",
      width:90,
      render: (record) =>
        record.LimitSw2 ? <img src="/images/limit_switch_red.jpeg" /> : "-",
    },
    {
      title: "Motor zast.",
      key: "MZastita",
      width:50,
      render: (record) =>
        record.MZastita ? <img src="/images/crvena_ledica.png" /> : "-",
    },
    {
      title: "Remark",
      key: "remark",
      dataIndex: "remark",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const [stats, setStats] = useState([]);

  const getData = (from, to, page = 1, limit = 100) => {
    setLoading(true);
    axios
      .get("https://grijanje.simundza.com/api/getData", {
        params: { from, to, page, limit },
      })
      .then((resp) => {
        setStats(resp.data.data);
        setTotal(resp.data.total);
        setLoading(false);
      });
  };

  const handleDatesChange = (from, to) => {
    if (from && to) {
      getData(from, to);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const filterData = (paginationData) => {
    let pagination = {
      page: paginationData.current,
      limit: paginationData.pageSize,
    };

    getData(undefined, undefined, pagination.page, pagination.limit);
  };

  return (
    <div>
      <div className="datepickerWrapper" style={{justifyContent:'flex-start'}}>
        <h3 style={{marginRight:'30px'}}>Tablica</h3>
        <RangeDatePicker
          initialStartDate={dayjs().subtract(7, "day")}
          initialEndDate={dayjs()}
          locale="hr"
          onChange={handleDatesChange}
          
        />
      </div>
      <Table
        rowKey={"id"}
        loading={loading}
        columns={columns}
        dataSource={stats}
        onChange={filterData}
        pagination={{
          total: total,
          pageSize: 100,
          showSizeChanger: false,
          showQuickJumper: false,
        }}
        scroll={{ x: 500 }}
      />
    </div>
  );
}
