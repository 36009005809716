import React, { useEffect, useState } from "react";
import {
  apexLineChartDefaultOption,
  COLOR_1,
  COLOR_2,
  COLOR_5,
  COLOR_6,
  COLOR_7,
  COLOR_8,
} from "../../chartConstants";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import dayjs from "dayjs";
import { RangeDatePicker } from "@y0c/react-datepicker";
import axios from "axios";

export default function Chart2({ data }) {
  const [series, setSeries] = useState([]);
  const [stats, setStats] = useState([]);


  useEffect(() => {
    if (data) {
      setStats(data);
    }
  }, [data]);

  useEffect(() => {
    let processed = {
      silos1: [],
      silos2: [],
      LimitSw1: [],
      LimitSw2: [],
      MZastita: [],
    };

    stats.map((item) => {
      processed["silos1"].push({ x: item.created_at, y: item.silos1 * 1 });
      processed["silos2"].push({ x: item.created_at, y: item.silos2 * 2 });
      processed["LimitSw1"].push({ x: item.created_at, y: item.LimitSw1 * 3 });
      processed["LimitSw2"].push({ x: item.created_at, y: item.LimitSw2 * 4 });
      processed["MZastita"].push({ x: item.created_at, y: item.MZastita * 5 });
    });

    setSeries([
      { name: "Silos 1", data: processed["silos1"] },
      { name: "Silos 2", data: processed["silos2"] },
      { name: "Limit Switch 1", data: processed["LimitSw1"] },
      { name: "Limit Switch 2", data: processed["LimitSw2"] },
      { name: "Motorna zastita", data: processed["MZastita"] },
    ]);
  }, [stats]);

  return (
    <div>
    
      <Chart
        options={{
          ...apexLineChartDefaultOption,
          stroke: {
            curve: "stepline",
            width:2
          },
          yaxis: {
            max: 5,
            tickAmount: 5,
            min:0
          },
          tooltip: {
            theme: "dark",
            y: {
              formatter: function (value, { series, seriesIndex }) {
                if (
                  seriesIndex === 2 ||
                  seriesIndex === 3 ||
                  seriesIndex === 4
                ) {
                  if (value > 0) {
                    return "TRIGGERED";
                  } else {
                    return "OK";
                  }
                }
                if (value > 0) {
                  return "ON";
                } else {
                  return "OFF";
                }
              },
            },
            x: {
              show: true,
              format: "dd MMM y HH:mm",
              formatter: undefined,
            },
          },
          chart: {
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
            },
            id: "chart2",
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
            },
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          colors: [COLOR_1, COLOR_2, COLOR_7, COLOR_8, COLOR_6],
        }}
        series={series}
        type="line"
        width="100%"
        height="450px"
      />
    
      )}
    </div>
  );
}
